.work__filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1.875rem;
  margin-bottom: 2.5rem;
}

.work__item {
  cursor: pointer;
  font-weight: var(--font-bold);
  transition: 0.3s;
}

.work__item:hover {
  color: var(--first-color);
}

.work__container {
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
}

.work__card {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  height: 260px; /* Adjust this value to make the cards taller */
}

.work__thumbnail {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.work__img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area */
  transition: transform 0.3s ease; /* Optional: Adds a smooth transition */
}

.work__mask {
  background: #6c6ce5;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.3s;
  opacity: 0;
}

.work__card:hover .work__mask {
  opacity: 0.9;
}

.work__category {
  color: #fff;
  background-color: var(--first-color);
  border-bottom-left-radius: 0.98rem;
  border-bottom-right-radius: 0.98rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
  font-size: var(--small-font-size);
  display: inline-block;
  padding: 0.19rem 0.625rem;
  transform: translateY(-40px);
  transition: 0.3s;
}

.work__title {
  color: #fff;
  font-size: var(--h3-font-size);
  padding: 0 1.25rem;
  position: absolute;
  top: 2.75rem; /* Điều chỉnh giá trị này để di chuyển tiêu đề lên trên */
  transform: translateY(30px);
  transition: 0.3s;
  opacity: 0;
}

.work__description {
  color: #fff;
  font-size: var(--small-font-size);
  position: absolute;
  top: 6rem;
  padding: 0 1.25rem;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(30px);
  max-height: 80px; /* Giới hạn chiều cao tối đa */
  overflow: auto; /* Thêm thanh cuộn khi nội dung vượt quá chiều cao tối đa */
  scrollbar-width: thin;   
  scrollbar-color: #FFD15C rgba(108, 108, 229, 0.2); 

}

.work__button {
  color: #fff;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  font-size: var(--h3-font-size);
  display: block;
  background-color: #ffd15c;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  transition: 0.3s;
  opacity: 0;
}

.work__card:hover .work__button {
  opacity: 1;
}

.work__card:hover .work__title,
.work__card:hover .work__description,
.work__card:hover .work__category {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 1024px) {
  .work__filters {
    justify-content: center;
  }

  .work__container {
      grid-template-columns: repeat(2, 330px);
      justify-content: center;
      row-gap: 1.875rem;
  }
}

@media screen and (max-width: 768px) {
  .work__container {
      grid-template-columns: 310px;
  }

  .work__filters {
    row-gap: .25rem;
  }
}

@media screen and (max-width: 350px) {
  .work__container {
      grid-template-columns: 1fr;
  }
}